import { UserGetResponse } from '@views/misc/auth/auth.types';
import { convertEnumValueToTextFromKey } from '@views/misc/enums/enums.helpers';
import { FetchEnumsResponse } from '@views/misc/enums/enums.types';
import { IUserVerifyV2, IdVerificationStatusEnum } from '@views/misc/user/user.types';

import { EnumChipType } from '@components/chip/chip.component';

import { SquirrelMoneyCoreEnumerationsAutoSellOptions } from '@core/schemas/api1-schema';

export const createImageIcon = (
  entityTypeId: number,
  enums: FetchEnumsResponse,
): {
  imageIconUrl: string;
  imageIconAlt: string;
} => {
  let entityName = convertEnumValueToTextFromKey(enums, 'entityTypeId', entityTypeId);

  entityName = entityName.replace(/[ ]/g, '');

  return {
    imageIconUrl: entityName && `/assets/images/investment-icons/${entityName}.png`,
    imageIconAlt: entityName && `${entityName} inventment icon`,
  };
};

export const CREATE_ENTITY_FLOW_ROUTE = `create-entity/profile`;
export const CREATE_ENTITY_FLOW_ROUTE_PREFIX = `/${CREATE_ENTITY_FLOW_ROUTE}`;

export const createEntityFromHomeRoute = ({
  userVerify,
  user,
}: {
  userVerify: IUserVerifyV2;
  user: UserGetResponse;
}): string => {
  if (userVerify === undefined || user === undefined) {
    return undefined;
  }
  if (user?.address === '' || user?.dateOfBirthNzt === null || user?.foreignTaxResident === null) {
    return CREATE_ENTITY_FLOW_ROUTE_PREFIX + '/incomplete';
  }

  // otherwise go to create page
  return createEntityFromProfileRoute({ userVerify });
};

export const createEntityFromProfileRoute = ({ userVerify }: { userVerify: IUserVerifyV2 }): string => {
  if (
    userVerify?.idVerificationStatus === IdVerificationStatusEnum.NotStarted ||
    userVerify?.idVerificationStatus === IdVerificationStatusEnum.Failed
  ) {
    return CREATE_ENTITY_FLOW_ROUTE_PREFIX + '/validate-id';
  }

  // otherwise go to create page
  return '/settings/entities/create';
};

export const returnRolloverStatusAsLabel = (
  autoSellRolloverInvestmentOnActivation: SquirrelMoneyCoreEnumerationsAutoSellOptions,
): string => {
  if (autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Keep) {
    return 'Will keep';
  } else if (
    autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Sell ||
    autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Undecided
  ) {
    // if ActivationId === 1(undecided) then set to 3(sell) as this is the default behaviour
    return 'Will sell';
  }
  return undefined;
};

export const getInvestmentAcountChipType = (isVerified: boolean): EnumChipType => {
  if (isVerified === true) {
    return EnumChipType.Active;
  } else if (isVerified === false) {
    return EnumChipType.Pending;
  } else {
    return undefined;
  }
};

export const getInvestmentAccountStatusLabel = (isVerified: boolean): string => {
  if (isVerified === true) {
    return 'Active';
  } else if (isVerified === false) {
    return 'Awaiting first deposit';
  } else {
    return undefined;
  }
};
