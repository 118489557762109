export const environment = {
  production: true,
  test: false,
  enablePlayground: true,
  logRoutes: false,
  intercomToken: 'agcfddsf',
  endpointApiOne: 'https://sq-money-staging-api1.azurewebsites.net',
  endpointApiTwo: 'https://sq-money-staging-api2.azurewebsites.net',
  endpointApiPortal: 'https://mms-staging-public-api.azurewebsites.net',
  endpointApiPortalTwo: 'https://mms-staging-adviser-api.azurewebsites.net',
  endpointAu10tix: 'https://eus-api.au10tixservicesstaging.com',
  endpointSecureMe: 'https://secure-me.au10tixservicesstaging.com',
  clientId: '6c7cfde629674f998cb8db9bf9bc0b5a',
  appInsightsInstrumentationKey: '39338bab-f827-458b-9923-37d4d74435d9',
  accountUrl: 'https://account-staging.squirrel.co.nz',
  onboardingUrl: 'https://sq-money-onboarding-cdn-staging.azureedge.net',
  olaUrl: 'https://squirrelmortgagesstaging.azureedge.net',
  googleAnalyticsKey: {
    measurementId: 'G-30TQ84TVET',
  },
  brazeAPIKey: '665176a3-3113-4eb4-a9f0-7394dd15acd2',
  brazeSDKEndpoint: 'sdk.iad-05.braze.com',
  brazeUserLookupEndpoint: 'https://data-apim.azure-api.net/braze_id_staging/squirrelExternalidLookupFunction',
  brazeUserLookupBearer: '7bf92184f97b493790149889ba2f7da5',
  authSignalTenantId: '7a9351d1-81fd-4a7e-adde-c442b2c29c7f',
  authSignalBaseUrl: 'https://au.api.authsignal.com/v1',
  firebase: {
    apiKey: 'AIzaSyAQ92EvWrzH16CvnkudMLSdXZ82ma-_rok',
    authDomain: 'squirrel-money-android.firebaseapp.com',
    databaseURL: 'https://squirrel-money-android.firebaseio.com',
    projectId: 'squirrel-money-android',
    storageBucket: 'squirrel-money-android.appspot.com',
    messagingSenderId: '447689775639',
    appId: '1:447689775639:web:83abe1a61eda3518b45a52',
    measurementId: 'G-NVNYEJ75H9',
    vapidKey: 'BMxPLt9QVhCQt5NNEJz28OJIYX9MvrXcNMa_pIau-RFevJCZ9dU9M7Vl9bNnqMc7_ABNJ2kEjOaD4ZfQrccy4js',
  },
  rayGunApiKey: 'nDe22j1wFACMrxVwVLAn4w',
};
